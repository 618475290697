<template>
  <div class="mt-5">
    <portal to="breadcrumb">
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </portal>
    <div class="">
      <div class="bg p-3">
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des natures</h4>
          </div>
          <form class="d-flex my-1">
            <div class="input-group mb-3 me-4">
              <input type="text" class="form-control" placeholder="" aria-label="Example text with button addon" aria-describedby="button-query">
              <button type="submit" class="style_btn btn btn-primary"  id="button-query"><i class="bi bi-search"></i></button>
            </div>
              <nature-edit ref="Natureditor" @saved="updateOrCreateNature" btnText="Créer un nature"  :item="activeNature" title="Ajouter un nature"></nature-edit>
          </form>
        </div>
        <div class="bg-white p-3 border shadow">
          <div class="table-responsive">
            <b-table show-empty empty-text="Aucune donnée à afficher" :fields="tableFields" class="align-middle" striped :items="tableData">
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <a  @click.prevent="editNature(data.item)" class="btn btn-success rounded-0 btn-sm me-1"><i class="bi bi-pencil-square"></i></a>
                  <a class="btn btn-info rounded-0 btn-sm me-1"><i class="bi bi-info-square"></i></a>
                  <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>Affichage de l’élément 1 à 38 de 38 éléments</p>
           <!-- <b-pagination
              :value="mxPagination.page"
              :total-rows="mxPageCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination> -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import NatureEdit from '@/components/espace/admin/nature/NatureEdit'

export default {
  components: {
    NatureEdit
  },
  data () {
    return {
      activeNature: {},
      breadcrumbs: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Paramètres',
          href: '#'
        },
        {
          text: 'Natures',
          active: true
        }
      ]
    }
  },
  created () {
    this.fetchNatures()
  },
  computed: {
    ...mapGetters({
      natures: 'nature/natures'
    }),
    tableFields () {
      return [
        'index',
        { key: 'name', label: 'Libellé' },
        // { key: 'structure', label: 'Structure' },
        'actions',
      ]
    },
    tableData () {
      return this.natures.map(item => ({ ...item }))
    }
  },
  methods: {
    ...mapActions({
      fetchNatures: 'nature/fetchNatures',
      createOrUpdateNature: 'nature/createOrUpdateNature',
    }),
    editNature (nature) {
      console.log(nature)
      this.activeNature = { ...nature }
      this.$refs['Natureditor'].show()
    },
    updateOrCreateNature (nature) {
      console.log(nature)
      this.createOrUpdateNature(nature).then(data => {
        console.log(data)
        this.$toast.success("Opération réussie!", {
            position: 'bottom-right',
            duration: 5000
          })
      })
    }
  }
}
</script>

<style>

</style>